// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-tsx": () => import("/opt/build/repo/src/fe/marketing/src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-legal-tsx": () => import("/opt/build/repo/src/fe/marketing/src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-customers-tsx": () => import("/opt/build/repo/src/fe/marketing/src/templates/customers.tsx" /* webpackChunkName: "component---src-templates-customers-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/fe/marketing/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/src/fe/marketing/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("/opt/build/repo/src/fe/marketing/src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-customers-tsx": () => import("/opt/build/repo/src/fe/marketing/src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/fe/marketing/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-issues-tsx": () => import("/opt/build/repo/src/fe/marketing/src/pages/login-issues.tsx" /* webpackChunkName: "component---src-pages-login-issues-tsx" */),
  "component---src-pages-new-tsx": () => import("/opt/build/repo/src/fe/marketing/src/pages/new.tsx" /* webpackChunkName: "component---src-pages-new-tsx" */),
  "component---src-pages-pricing-tsx": () => import("/opt/build/repo/src/fe/marketing/src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-waiting-list-tsx": () => import("/opt/build/repo/src/fe/marketing/src/pages/waiting-list.tsx" /* webpackChunkName: "component---src-pages-waiting-list-tsx" */)
}

