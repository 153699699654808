module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":true,"showCaptions":true,"maxWidth":590},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-remark-prettier",{"resolve":"gatsby-remark-vscode","options":{"colorTheme":"Quiet Light"}}]},
    },{
      plugin: require('/opt/build/repo/src/fe/marketing/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
